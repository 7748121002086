import React from "react"
import { useEffect } from "react"

// @material-ui/icons
// import Button from "@material-ui/core/Button"
import Button from "components/CustomButtons/Button.js"
import Hidden from "@material-ui/core/Hidden"
// import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
// core components
import useFirebase from "components/useFirebase"
import Moment from "react-moment"

function Groups({ ...props }) {
  const firebase = useFirebase()
  const [groups, setGroups] = React.useState([])

  useEffect(() => {
    if (!firebase) return
    firebase
      .database()
      .ref("POCtest/groups/metadata")
      .once("value")
      .then(snapshot => {
        setGroups(snapshot.val())
      })
  }, [firebase])

  return (
    <TableContainer autoWidth={false}>
      <Table stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">出團日期</TableCell>
            <TableCell>費用(加幣)</TableCell>
            <TableCell>團名/代號</TableCell>
            <Hidden smDown>
              <TableCell>導遊</TableCell>
            </Hidden>
            <TableCell>空位</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[]
            .concat(groups)
            .sort((a, b) => a.groupStart - b.groupStart)
            .filter(group => {
              return props.groupType
                ? group.groupType === props.groupType
                : true
            })
            .filter(group => {
              return props.groupDays
                ? group.groupDays === props.groupDays
                : true
            })
            .filter(group => {
              return props.groupName
                ? group.groupName.includes(props.groupName)
                : true
            })
            .map(group => (
              <TableRow key={group.id + group.groupFee}>
                <TableCell align="center" component="th" scope="row">
                  <Moment format="M/DD">{group.groupStart}</Moment> -{" "}
                  <Moment format="M/DD">{group.groupEnd}</Moment>
                </TableCell>
                <TableCell>${group.groupFee}</TableCell>
                <Hidden smDown>
                  <TableCell>{group.groupName}</TableCell>
                  <TableCell>{group.guides}</TableCell>
                </Hidden>
                <TableCell>{group.groupOpen}</TableCell>
                <TableCell>
                  <Button
                    // variant="contained"
                    style={{ fontSize: 14 }}
                    color={group.groupOpen === "已額滿" ? "default" : "primary"}
                  >
                    {group.groupOpen === "已額滿" ? "候補" : "報名"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default React.memo(Groups)
